<template>
  <HelloWorld />
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    HelloWorld,
  },
};
</script>

<style>
/* *{
  margin: 0;
  padding: 0;
} */
@media (min-width: 700px) {
  html {
    overflow: -moz-hidden-unscrollable;
    height: 100%;
  }

  body::-webkit-scrollbar {
    display: none;
  }

  body {
    -ms-overflow-style: none;
    height: 100%;
    width: calc(100vw + 18px);
    overflow: auto;
  }
}
</style>
