<template>
  <div class="center">
    <video v-if="checkDevice()==='ios'" controls style="width: 100%;height:56.25vw;" :src="src"></video>
    <videoPlay
    v-else
      controlslist="nodownload noremoteplayback"
      v-bind="options"
      @play="onPlay"
      @pause="onPause"
      @timeupdate="onTimeupdate"
      @canplay="onCanplay"
    />
    <div class="who"><span>培训人员</span>{{ videoinfo.trainer }}</div>
    <div class="who"><span>课程类型</span>{{ videoinfo.name }}</div>
    <div class="who"><span>培训内容</span>{{ videoinfo.content }}</div>
    <div class="who">
      <span>培训日期</span>{{ videoinfo.year }}年{{ videoinfo.month }}月{{
        videoinfo.day
      }}日
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { reactive, ref } from "vue";
import "vue3-video-play/dist/style.css";
import { videoPlay } from "vue3-video-play";
import { encrypt, decrypt } from "../ulits/jsencrypt.js";
export default {
  components: {
    videoPlay,
  },
  setup() {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    let datainfo = {};
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      datainfo[pair[0]] = decodeURI(pair[1]);
    }
    let ces = encrypt("1");
    console.log(
      2,
      decrypt(
       ces
      )
    );
    let videoinfo = ref({});
    let src = ref("");
    const getinfo = () => {
      axios({
        method: "post",
        url: "https://adminapi.tuoyuyun.cn/adminapi/teachingvideo/details",
        data: {
          id: datainfo.id,
        },
      }).then((res) => {
        videoinfo.value = res.data.data;
        //生成正则将res.data.data.video的http://转换为https://
        let reg = /http:\/\//g;
        let newurl = res.data.data.video.replace(reg, "https://");
        src.value = newurl;
      });
    };
    getinfo();

    //一个检查设备是什么手机的函数
    const checkDevice = function () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        return "android";
      } else if (isiOS) {
        return "ios";
      } else {
        return "pc";
      }
    };


    const options = reactive({
      width: "100%", //播放器高度
      height: "56.25vw", //播放器高度
      color: "#409eff", //主题色
      muted: false, //静音
      webFullScreen: false,
      speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
      autoPlay: false, //自动播放
      loop: false, //循环播放
      mirror: false, //镜像画面
      ligthOff: false, //关灯模式
      volume: 0.3, //默认音量大小
      control: true, //是否显示控制器
      title: "", //视频名称
      src: src, //视频源
      poster: "", //封面
    });
    const onPlay = (ev) => {
      console.log(ev, "播放");
    };
    const onPause = (ev) => {
      console.log(ev, "暂停");
    };

    const onTimeupdate = () => {
      // console.log(ev, "时间更新");
    };
    const onCanplay = (ev) => {
      console.log(ev, "可以播放");
    };

    return {
checkDevice,
      options,
      onPlay,
      onPause,
      onTimeupdate,
      onCanplay,
      datainfo,
      videoinfo,
      src
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 700px) {
  .center {
    margin: 0 300px;
    padding-bottom: 100px;
  }
}

.who {
  margin: 20px;
  color: #3b9f88;
  font-size: 18px;
  letter-spacing: 5px;
}
.who span {
  display: inline-block;
  background: #3b9f88;
  color: #fff;
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 16px;
  margin-right: 20px;
}
</style>
